<template>
  <section>
    <wrapper-collapse
        :title="row.headerTitle"
        initial-open
        active-class="border-active-content"
        v-for="(row, i) in arrFiltered" :key="i"
    >
      <card-metodics-file :items="row.children" @click-file="getUploadedScanView($event)"/>
    </wrapper-collapse>
  </section>
</template>

<script>
import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
import CardMetodicsFile from "@/components/cards/cardMetodicsFile";
import {mapGetters} from "vuex";
import request from "@/services/request";
import axios from "axios";
const availableYears = [2022, 2021]
export default {
  name: "QuotaPrintPrevYearsFiles",
  components: {CardMetodicsFile, WrapperCollapse},
  data() {
    return {
      arr: [
        {
          headerTitle: 'Печать предложений по целевой квоте за предыдущие года',
          roles: ['REGION_ROLE', 'FOUNDER_ROLE'],
          id: Math.random(),
          children: [
            // {
            //   id: '',
            //   title: `Система прогнозирования потребности в подготовке кадров с высшим образованием и планирование на ее основе общих объемов контрольных цифр приема (Презентация)`,
            //   link: 'https://files.ined.ru/files/ppk/1_vvodnaya_ppk.pdf',
            //   name: 'Файл PDF'
            // },
          ]
        },
      ],
    scanTypes: availableYears.reduce((acc, year) => {
      acc.push(`DISTRIBUTION_MAIN_SCAN_${year}`)
      acc.push(`DISTRIBUTION_LETTER_OUT_OF_SCAN_${year}`)
      return acc
    },[])
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getRoles: 'getUserRoles',
      getUser: 'getUser',
      isRegion: 'isRegion',
      getAuthData: 'getAuthData',
    }),
    arrFiltered() {
      return this.arr.filter(el => {
        let availableRoute = false;
        el.roles.forEach(el2 => {
          if (this.getRoles.includes(el2))
            availableRoute = true
        })
        if (availableRoute) return el
      })
    },
  },
  created() {
    this.getUploadedScan()
  },
  methods: {
    async getUploadedScan() {
      try {
        let uri;
        if (this.isRegion) {
          uri = `/api/moduledatagather/scan?regionId=${this.getUser.regionID}`
        } else {
          uri = `/api/moduledatagather/scan?founderId=${this.getUser.founderID}`
        }
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data.length !== 0) {
          console.log('getUploadedScan успех');
          this.arr[0].children = res.data.filter(el => this.scanTypes.includes(el.scanType)).map(el => ({
            ...el,
            emit: true,
            title: el.scanType.includes(`DISTRIBUTION_MAIN_SCAN_`) ? 'Предложение по целевой квоте' : 'Письмо об отсутствии предложений по целевой квоте',
            name: el.name ?? 'Файл'
          }))
        } else {
          console.log(res.data.length);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getUploadedScanView(item) {
      const {id} = item;
      console.log('item', item)
      let responseAxios = await axios.get(`/api/moduledatagather/scan/get?scanId=${id}`, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.downloadNow = false;
      this.saveByteArray("ScanView.pdf", responseAxios.data);
      this.uploadedFileLoading = false;
    },

    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/pdf"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
  },
}
</script>

<style lang="sass">
.border-active-content
  .collapse__toggle
    border-bottom: 1px solid #CFD8DC
    padding-bottom: 14px
</style>