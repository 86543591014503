<template>
  <div style="width: 24px!important">
    <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M25 5V19.1111C25 19.3469 24.9063 19.573 24.7397 19.7397C24.573 19.9063 24.3469 20 24.1111 20H2C1.73478 20 1.48043 19.8946 1.29289 19.7071C1.10536 19.5196 1 19.2652 1 19V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H8.66667C8.88304 1 9.09357 1.07018 9.26667 1.2L12.7333 3.8C12.9064 3.92982 13.117 4 13.3333 4H24C24.2652 4 24.5196 4.10536 24.7071 4.29289C24.8946 4.48043 25 4.73478 25 5Z"
          :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "FolderIcon",
  props: {
    color: {
      type: String,
      default: '#0288D1'
    },
  },
}
</script>

<style scoped>

</style>