<template>
  <div style="width: 24px!important">
    <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M13.375 1.675V10.45H21.625M3.75 28H20.25C21.7688 28 23 26.7912 23 25.3V11.5684C23 10.8523 22.7103 10.1655 22.1945 9.65918L14.1805 1.79082C13.6647 1.28446 12.9653 1 12.236 1H3.75C2.23122 1 1 2.20883 1 3.7V25.3C1 26.7912 2.23122 28 3.75 28Z"
          :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "FileIcon",
  props: {
    color: {
      type: String,
      default: '#0288D1'
    },
  },
}
</script>

<style scoped>

</style>