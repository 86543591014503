<template>
  <div ref="card-method">
    <template v-if="items && items.length">
      <template v-for="(row) in items">
        <div v-if="row.children" :key="row.id" >
          <div  @click="open = !open" @mouseover="color = hoverColor" @mouseout="color = defaultColor">
            <div class="card-methodic" :style="`margin-left: ${level*36}px`">
              <FolderIcon :color="color"/>
              <div class="card-methodic-info">
                <div class="font-weight-600">
                  {{ row.title }}
                </div>
              </div>
            </div>
          </div>
          <template v-if="open" >
            <card-metodics-file :item="row" :items="row.children" :level="level + 1" />
          </template>
        </div>
        <template v-else>
          <card-metodics-file :key="row.id" :item="row" :items="row.children" :level="level" />
        </template>
      </template>
    </template>
    <template v-else-if="item">
      <div class="card-methodic" :style="`margin-left: ${level*36}px`" @click="openFile" @mouseover="color = hoverColor" @mouseout="color = defaultColor">
        <FileIcon :color="color"/>
        <div class="card-methodic-info">
          <div class="font-weight-600">
            {{item.title}}
          </div>
          <div class="card-methodic-info-help">
            {{item.name}}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import FolderIcon from "@/components/iconsComponents/FolderIcon";
import FileIcon from "@/components/iconsComponents/FileIcon";
const doubleClickRemove = e => {
  if (e.detail > 1)
    e.preventDefault()
}
export default {
  name: "cardMetodicsFile",
  components: {FileIcon, FolderIcon},
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    item: {
      type: Object,
      default: null
    },
    level: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      open: false,
      defaultColor: '#0288D1',
      hoverColor: '#EB722E',
      color: '#0288D1'
    }
  },
  mounted() {
    this.$refs['card-method'].addEventListener('mousedown',doubleClickRemove, false)
  },
  beforeDestroy() {
    this.$refs['card-method'].removeEventListener('mousedown',doubleClickRemove, false)
  },
  methods: {
    openFile() {
      // console.log(this.item.id)
      if (this.item.emit){
        this.$emit('click-file',this.item)
        return
      }
      const a = document.createElement('a');
      a.href = this.item.link;
      a.target = '_blank';
      a.click();
      a.remove();
    }
  },

}
</script>

<style scoped>

</style>